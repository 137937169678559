import { Component, HostBinding, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Widget } from '../../interfaces/widget.interface';
import { LanguageService } from '../../services/language/language.service';
import {
  WidgetLocation,
  WordpressService,
} from '../../services/wordpress/wordpress.service';
/**
 * Loads in all the widgets from wordpress for a given location
 *
 * @export
 * @class WidgetComponent
 */
@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
})
export class WidgetComponent implements OnInit {
  @Input() location: WidgetLocation;
  @Input() beneficiaryId: string;
  @Input() authorized: 'true' | 'false';
  @HostBinding('class.u-hidden') isEmptyForClass: boolean = true;

  private isEmpty$ = new BehaviorSubject<boolean>(true);
  @Output() isEmpty = this.isEmpty$.asObservable();

  public environmentSettings = environment.features;
  public widgets: Widget[];

  constructor(
    private wordpressService: WordpressService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.languageService.selected
      .pipe(
        filter((language) => language !== ''),
        switchMap((lang: string) =>
          this.wordpressService.getWidgetsForLocation(
            this.location,
            this.beneficiaryId,
            this.authorized === 'true',
            lang
          )
        )
      )
      .subscribe(
        (widgets: Widget[]) => this.handleSuccess(widgets),
        () => console.error(`Error widgets for location ${this.location}`)
      );
  }

  private handleSuccess(widgets: Widget[]): void {
    this.widgets = widgets;
    this.isEmptyForClass = widgets ? !widgets.length : false;
    this.isEmpty$.next(this.isEmptyForClass);
  }
}
